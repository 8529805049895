<template>
  <prime-accordion
    :active-index="activeIndex"
    :pt="{
      root: { class: ['de-accordion'] },
      accordiontab: {
        header: { class: ['de-accordion-tab-header heading-h3', headerClasses] },
        headerAction: { class: ['de-accordion-tab-header-action', headerActionClasses] },
        content: { class: ['de-accordion-tab-content body-b2'] },
        transition: {
          enterFromClass: 'de-toggleable-content-enter-from',
          enterActiveClass: 'de-toggleable-content-enter-active',
          enterToClass: 'de-toggleable-content-enter-to',
          leaveFromClass: 'de-toggleable-content-leave-from',
          leaveActiveClass: 'de-toggleable-content-leave-active',
          leaveToClass: 'de-toggleable-content-leave-to',
        },
      },
    }"
  >
    <prime-accordion-tab v-for="tab in tabs" :key="tab.title">
      <template #header>
        <slot :name="`header-${tab.id}`">
          <slot name="header" :item="tab">
            {{ tab.title }}
          </slot>
        </slot>
      </template>

      <slot :name="`content-${tab.id}`" :item="tab">
        <!-- eslint-disable vue/no-v-html -->
        <slot name="content">
          <p :class="contentClass(tab)" v-html="tab.content" />
        </slot>
        <!--eslint-enable-->
      </slot>

      <template #headericon="scope">
        <slot name="headericon">
          <de-svg-icon
            name="chevron-down"
            class="de-accordion-tab-header-icon"
            :class="[{ 'tw-transform tw-rotate-180': scope.active }, headerIconClass]"
          />
        </slot>
      </template>
    </prime-accordion-tab>
  </prime-accordion>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { AccordionTab } from '~/shared/components/lib/accordion/accordion-tab.interface';
import DeSvgIcon from '~/shared/components/lib/svg-icon/DeSvgIcon.vue';
import type { TabItem } from '~/shared/components/lib/tabs/tab.interface';

const props = withDefaults(
  defineProps<{
    tabs: AccordionTab[];
    activeIndex?: number | number[] | null;
    headerClass?: string;
    headerActionClass?: string;
    headerIconClass?: string | string[];
    headerIconPosition?: 'start' | 'end' | 'space-between';
    contentClass?: (_item: TabItem) => string;
  }>(),
  {
    headerIconPosition: 'start',
    contentClass: (_item: TabItem) => '',
  },
);

const headerClasses = computed(() => {
  const classes = [];

  if (props.headerClass) {
    classes.push(props.headerClass);
  }

  return classes;
});
const headerActionClasses = computed(() => {
  const classes = [props.headerActionClass];

  if (props.headerIconPosition === 'end') {
    classes.push('tw-flex-row-reverse');
  }

  if (props.headerIconPosition === 'space-between') {
    classes.push('tw-w-full tw-flex-row-reverse');
  }

  return classes;
});
</script>

<style lang="postcss">
.de-accordion {
  @apply tw-flex tw-flex-col tw-gap-2.5;
}

.de-accordion-tab-header {
  @apply tw-flex tw-py-2.5;
}

.de-accordion-tab-header-action {
  @apply tw-inline-flex tw-items-center tw-justify-between tw-gap-1.5;
}

.de-accordion-tab-header-icon {
  @apply tw-w-600 tw-h-600 tw-text-primary-400;
}

.de-accordion-tab-content {
  @apply tw-mt-2.5;
}
</style>
